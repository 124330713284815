<template>
    <div>
      <div class="input-group mb-3">
    <input type="text" v-model="files" class="form-control" readonly :placeholder="placeholder" :aria-label="placeholder">
    <div class="input-group-append">
    <button class="btn btn-outline-danger btn-sm" v-if="canCanel" type="button" @click="clear">Clear</button>
    <button class="btn btn-primary" type="button" id="picker" v-b-modal="'file-picker'" >{{buttonText}}</button>
    </div>

      <b-modal id="file-picker"  size="xl" title="File Browser" centered no-close-on-backdrop lazy  hide-footer>
    <FileLibrary  @onFileSelected="fileSelected"/></b-modal>
</div>
 <div class="clearfix" v-if="showPreview">
  <img class="thumbnail" v-for="(f, index) of selectedFiles" :key="index" :src="f.thumbnailLink"/></div>
    </div>
</template>

<script>
import FileLibrary from './FileLibrary.vue';

export default {
  name: 'FilePicker',
  components: { FileLibrary },
  props: {
    placeholder: {
      type: String,
      default: 'File Picker',
    },
    buttonText: {
      type: String,
      default: 'File Picker',
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedFiles: [],
    };
  },
  computed: {
    files() {
      return this.selectedFiles.map(x => x.name);
    },
    canCanel() {
      return this.selectedFiles.length > 0;
    },
  },
  methods: {
    fetchFolderFiles(parent) {
      if (!parent) {
        this.$emit('fetchFolderFiles');
        return;
      }
      this.$emit('fetchFolderFiles', parent.id, parent.name);
    },
    fileSelected($event) {
      this.selectedFiles = [...$event];
      this.$bvModal.hide('file-picker');
    },
    clear() {
      this.selectedFiles = [];
    },
  },
  beforeDestroy() {
    this.$off(['fileSelected']);
  },
};
</script>
<style lang="stylus" scoped>
</style>
